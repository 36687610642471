/* eslint-disable react/prop-types */
import React from 'react';
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0
    ? (
    <div className='HitCount'>
      {hitCount} result{hitCount !== 1 ? 's' : ''}
    </div>
      )
    : null;
});

const PageHit = ({ hit }) => {
  return (
    <div>
      <a href={`/blog/${hit.slug}`}>
        <h4>
          <Highlight attribute='title' hit={hit} tagName='mark' />
        </h4>
      </a>
      <Snippet attribute='excerpt' hit={hit} tagName='mark' />
    </div>
  );
};

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className='Hits' hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
);

export default SearchResult;

SearchResult.propTypes = {
  indices: PropTypes.array.isRequired,
  className: PropTypes.string
};
