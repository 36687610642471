import React from 'react';
import { TextField } from '@material-ui/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.span`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.colors.link.base};
  }
  fieldset {
    border-color: ${props => props.theme.colors.link.base} !important;
  }
  fieldset:focused {
    border-color: ${props => props.theme.colors.link.active} !important;
  }
`;

const StyledInput = ({ color, data, id, label, name, onChange, placeholder, required, size, type, value, variant }) => {
  return (
    <Wrapper>
      <TextField
        required={required}
        id={id || name}
        data-cy={data}
        name={name || id}
        size={size}
        color={color}
        type={type}
        value={value}
        onChange={onChange}
        variant={variant}
        label={label}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default StyledInput;

StyledInput.propTypes = {
  color: PropTypes.string,
  data: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string
};

StyledInput.defaultProps = {
  color: 'primary',
  required: false,
  size: 'medium',
  type: 'text',
  variant: 'outlined'
};
