import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: block;
  input {
    display: none;
  }
  label {
    display: inline;
  }
  .MuiTypography-root {
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiFormControlLabel-label {
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${props => props.theme.colors.link.hover};
  }
  .checkContainer {
    padding: 7px;
    margin-top: -2px;
    margin-left: 0;
  }
  .checkContainer:first-of-type {
    margin-left: -7px;
  }
  .checkContainer:not(:only-child) {
    margin-right: 2px;
  }
  .checkContainer:hover {
    background-color: ${props => props.theme.colors.white.light};
  }
  .colorPrimary {
    color: ${props => props.theme.colors.primary.base};
  }
  .isSelected {
    color: ${props => props.theme.colors.primary.hero};
  }
  .isDisabled {
    color: ${props => props.theme.colors.white.gray};
  }
`;

const StyledCheckbox = ({ checked, color, data, id, disabled, label, name, onChange }) => {
  return (
    <Wrapper>
      <FormGroup row>
      <FormControlLabel
        control={
        <Checkbox
          id={id}
          data-cy={data}
          name={name}
          checked={checked}
          onChange={onChange}
          color={color}
          disabled={disabled}
        />}
        label={label}
      />
      </FormGroup>
    </Wrapper>
  );
};

export default StyledCheckbox;

StyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  data: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
};

StyledCheckbox.defaultProps = {
  checked: false,
  color: 'primary',
  disabled: false
};
