import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Search as SearchIcon } from '@styled-icons/fa-solid';

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <input
        id='searchInput'
        className='SearchInput form-control'
        type='search'
        placeholder='Search'
        aria-label='Search'
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onReset={e => refine('')}
        onFocus={onFocus}
      />
      <SearchIcon className='SearchIcon' size='16' />
    </form>
  )
);
