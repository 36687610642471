import React from 'react';
import styled from '@emotion/styled';
// import scrollTo from 'gatsby-plugin-smoothscroll';

const Wrapper = styled.footer`
  position: relative;
  bottom: 0;
  background: ${props => props.theme.colors.primary.hero};
  font-family: ${props => props.theme.fontFamily.body};
  a {
    color: ${props => props.theme.colors.black.base};
  }
  a:hover {
    color: ${props => props.theme.colors.link.hover};
    text-decoration: underline;
  }
`;

const Copyrights = styled.div`
  p {
    padding-top: 20px;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }
  a {
    text-decoration: underline;
  }
`;

const year = new Date().getFullYear();

const Footer = () => (
  <Wrapper className='footer'>
    <Copyrights>
      <p>&copy; {year} Stackery. All rights reserved.</p>
    </Copyrights>
  </Wrapper>
);

export default Footer;
