/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  background: ${props => props.theme.colors.button.primary};
  border-radius: 4px;
  padding: .5rem 1rem;
  cursor: pointer;
  h4, p {
    color: ${props => props.theme.colors.white.white};
    background-color: transparent;
    background-size: cover;
  }
  .icon {
    width: 50px;
    margin: 20px auto 1rem auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 2rem;
  }
`;

const LinkBox = ({ data, helperText, href, icon, onClick, title, to }) => {
  let Elem = 'span';
  if (to) {
    Elem = Link;
  } else if (href) {
    Elem = 'a';
  }
  return (
    <Elem href={href} to={to} onClick={onClick} target={href ? '_blank' : '_self'} data-cy={data}>
      <Wrapper>
        {icon &&
          <img className='icon' src={icon} alt={title} />
        }
        <h4>{title}</h4>
        <p>{helperText}</p>
      </Wrapper>
    </Elem>
  );
};

export default LinkBox;

LinkBox.propTypes = {
  data: PropTypes.string,
  helperText: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
};
