import React from 'react';
import styled from '@emotion/styled';
import { PrimaryLink, StyledButton } from 'components/core';
import heroImg from '../../static/img/hero-light.svg';

const Wrapper = styled.div`
  position: relative;
  padding: 4rem 20vw;
  display: block;
  color: ${props => props.theme.colors.white.white};
  background: ${props => props.theme.colors.blue.darker} url(${heroImg}) no-repeat fixed center;
  background-size: cover;
  align-items: center;
  text-align: center;
  h2 {
    color: ${props => props.theme.colors.white.white};
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
  }
  p{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
  }
  button {
    margin: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 4rem 2rem;
  }
`;

const HomeCta = () => (
  <Wrapper className='homeCta'>
    <h2>Want to go faster?</h2>
    <p>Design, develop, and deliver modern applications securely with Stackery.</p>
    <a href='https://hs.stackery.io/demo-request-base'><StyledButton color='secondary' size='large' variant='contained' type='text' text='See a Demo' noShadow /></a>
    <PrimaryLink to='/sign-up?pricing=team' text='Try Stackery for free' target='_self' />
  </Wrapper>
);

export default HomeCta;
