import React, { Component } from 'react';
import { LinearProgress } from '@material-ui/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const randomPhrases = [
  'Adjusting S3 curves',
  'Calculating lambda expectoration trajectory',
  'Calibrating Route53 as a database',
  'Applying feng shui S3 bucket',
  'Breeding faunaDB',
  'Climbing elastic beanstalk',
  'Compounding free tier interest',
  'Dicing database models',
  'Draining Cognito user pool',
  'Fixing ElastiCashe outcome matrix',
  'Flushing CI/CD pipeline network',
  'Graphing database migration',
  'Inserting sublimated SQS messages',
  'Mopping data leaks',
  'Playing in the snow family',
  'Reconfiguring managed blockchain processes',
  'Reticulating serverless splines',
  'Routing deep learning infanstructure',
  'Warping in API Gateways'
];

function getRandomNumber () {
  return Math.floor(Math.random() * (randomPhrases.length - 1) + 1);
}

function showRandomPhrase () {
  const randomPhrase = `⌛ ${randomPhrases[getRandomNumber()]}...`;
  return randomPhrase;
}

const Wrapper = styled.div`
  .MuiLinearProgress-root {
    margin-bottom: 1rem;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${props => props.theme.colors.teal.base};
  }
  .MuiLinearProgress-colorSecondary {
    background-color: ${props => props.theme.colors.red.base};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => props.theme.colors.teal.light};
  }
  .MuiLinearProgress-barColorSecondary {
    background-color: ${props => props.theme.colors.red.light};
  }
`;

class LoadingBar extends Component {
  state = {
    phrase: showRandomPhrase()
  }

  phraseInterval = null;

  componentDidMount (prevProps, prevState) {
    this.phraseInterval = setInterval(() => this.setState({ phrase: showRandomPhrase() }), 500);
  }

  componentWillUnmount () {
    clearInterval(this.phraseInterval);
  }

  render () {
    return (
      <Wrapper>
        <LinearProgress color={this.props.secondary ? 'secondary' : 'primary'} />
        <p><em>{this.state.phrase}</em></p>
      </Wrapper>
    );
  }
}

export default LoadingBar;

LoadingBar.propTypes = {
  secondary: PropTypes.bool
};
