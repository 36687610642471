/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { CarouselItem } from 'components';
import design from '../../static/img/home/carousel-design.svg';
import develop from '../../static/img/home/carousel-develop.svg';
import deliver from '../../static/img/home/carousel-deliver.svg';

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white.white};
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 2rem;
  align-items: start;
  padding: 6rem 0 6rem 2rem;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: 1fr;
    padding: 2rem 0 2rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const CarouselTextContainer = styled.div`
  padding: 2rem;
  text-align: left;
  h3 {
    margin-top: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-right: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
    padding: 1rem;
  }
`;

const CarouselImgContainer = styled.div`
  img {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const Carousel = ({ handleClick, imageSrc, isDesignActive, isDevelopActive, isDeliverActive }) => {
  const breakpoints = useBreakpoint();

  return (
    <Wrapper>
      <CarouselTextContainer>
        <span id='design' onClick={handleClick}>
          <CarouselItem
            title='Design with confidence.'
            text='Architect modern apps quickly with an intuitive virtual whiteboard that turns your infrastructure sketch into code.'
            linkText='Learn more about designing your infrastructure'
            linkHref='/product#design'
            active={isDesignActive}
          />
          {breakpoints.m ? <img src={design} alt='design' /> : null}
        </span>
        <span id='develop' onClick={handleClick}>
          <CarouselItem
            title='Develop rapidly.'
            text='Build and test modern applications quickly and with security built-in.'
            linkText='Learn more about developing with Stackery'
            linkHref='/product#develop'
            active={isDevelopActive}
          />
          {breakpoints.m ? <img src={develop} alt='develop' /> : null}
        </span>
        <span id='deliver' onClick={handleClick}>
          <CarouselItem
            title='Deliver securely.'
            text='Consistent security and governance from laptop to production as your team scales.'
            linkText='Learn more about CI/CD and Stackery'
            linkHref='/product#deliver'
            active={isDeliverActive}
          />
          {breakpoints.m ? <img src={deliver} alt='deliver' /> : null}
        </span>
      </CarouselTextContainer>
      <CarouselImgContainer>
        {!breakpoints.m ? <img src={imageSrc} alt='deliver' /> : null}
      </CarouselImgContainer>
    </Wrapper>
  );
};

export default Carousel;

Carousel.propTypes = {
  handleClick: PropTypes.func,
  imageSrc: PropTypes.string,
  isDesignActive: PropTypes.bool,
  isDevelopActive: PropTypes.bool,
  isDeliverActive: PropTypes.bool
};
