import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import heroImg from '../../static/img/hero-light.svg';
import { StyledButton } from 'components/core';

const Wrapper = styled.div`
  position: relative;
  padding: 4rem 20vw;
  display: block;
  color: ${props => props.theme.colors.white.white};
  background: ${props => props.theme.colors.blue.darker} url(${heroImg}) no-repeat fixed center;
  background-size: cover;
  align-items: center;
  text-align: center;
  h2 {
    color: ${props => props.theme.colors.white.white};
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
  }
  p{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
  }
  a {
    margin: 10px 0 0 10px;
  }
  span {
    display: block;
    margin-top: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 4rem 2rem;
  }
`;

const ReinventCta = () => (
  <Wrapper className='homeCta'>
    <h2>Need help navigating AWS re:Invent?</h2>
    <p>Our Guide to Serverless at re:Invent is your one stop shop for all serverless annoucements – and what they mean for you.</p>
    <Link to={'/reinvent'}><StyledButton color='secondary' size='large' variant='contained' type='text' text='Learn more' noShadow /></Link>
  </Wrapper>
);

export default ReinventCta;
