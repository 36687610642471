import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { PrimaryLink } from 'components/core';

const CarouselItem = ({ title, text, linkText, linkHref, active }) => {
  const CarouselItemContainer = styled.div`
    cursor: pointer;
    padding: 0 0 2rem 2rem;
    border-left: ${active ? '8px solid #34A380' : '2px solid #ECEDEE'};
    @media (max-width: ${props => props.theme.breakpoints.l}) {
      border-left: none;
      border-top: ${active ? '8px solid #34A380' : '2px solid #ECEDEE'};
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      border-left: none;
      border-top: none;
    }
  `;

  return (
    <CarouselItemContainer>
      <h3>{title}</h3>
      <p>{text}</p>
      <PrimaryLink to={linkHref} text={linkText} target='_self' />
    </CarouselItemContainer>
  );
};

export default CarouselItem;

CarouselItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  active: PropTypes.bool,
  theme: PropTypes.object
};
