import React, { Fragment } from 'react';
import { css, Global, ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { Footer, NavBar } from 'layouts';
import theme from '../../config/theme';
import headroom from '../styles/headroom';

const Layout = ({ background, children, location, seoTitle, seoDescription, seoImage }) => {
  // This allows a background option to be passed into the layout, and defaults to a white background
  function getBackground (color) {
    switch (color) {
      case 'dark':
        return theme.colors.background.dark;
      case 'navy':
        return theme.colors.blue.dark;
      case 'teal':
        return theme.colors.teal.light;
      default:
        return theme.colors.white.white;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Global
        id='top'
        styles={css`
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          html {
            text-rendering: optimizeLegibility;
            box-sizing: border-box;
            -ms-overflow-style: scrollbar;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          html,
          body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
          }
          body {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            background: ${getBackground(background)};
          }
          a {
            color: ${theme.colors.link.base};
            transition: color 0.5s;
            text-decoration: none;
          }
          a:hover {
            text-decoration: none;
            color: ${theme.colors.link.hover};
          }
          h1 {
            font-family: ${theme.fontFamily.heading};
          }

          ${headroom}
        `}
      />
      <Fragment>
        <SEO
          title={seoTitle}
          description={seoDescription}
          banner={seoImage}
        />
        <NavBar location={location} />
        {children}
        <Footer />
      </Fragment>
    </ThemeProvider>
  );
};

export default Layout;

Layout.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  location: PropTypes.object,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoImage: PropTypes.string
};
