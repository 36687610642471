import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { PrimaryLink } from 'components/core';

const Wrapper = styled.div`
  margin-bottom: 10px;
  h3 {
    margin-top: 10px;
  }
  img {
    margin: 0;
    padding: 0;
  }
`;

const Feature = ({ icon, title, text, linkText, linkUrl, internalLink }) => (
  <Wrapper>
    <img src={icon} alt={title} />
    <h3>{title}</h3>
    <p>{text}</p>
    {linkUrl && <PrimaryLink to={internalLink ? linkUrl : null} href={internalLink ? null : linkUrl} text={linkText} />}
  </Wrapper>
);

export default Feature;

Feature.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  internalLink: PropTypes.bool
};
