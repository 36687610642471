import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ChevronRight, ChevronLeft } from '@styled-icons/fa-solid';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.span`
  a, span {
    color: ${props => props.theme.colors.red.base};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  a:hover, span:hover {
    text-decoration: underline;
  }
  a:active, span:active {
    text-decoration: underline;
    color: ${props => props.theme.colors.red.active};
  }
  .backIcon {
    margin-right: 4px;
    padding: 2px;
  }
  .linkIcon {
    margin-left: 4px;
    padding: 2px;
  }
`;

const PrimaryLink = ({ href, isReturnLink, onClick, target, text, to, id }) => {
  let Elem = 'a';
  const attrs = { href: href, target: target, onClick: onClick, id };

  if (to) {
    Elem = to.includes('#') ? AnchorLink : Link;
    attrs.to = to;
  } else if (!href && !to) {
    Elem = 'span';
  }

  return (
    <Wrapper>
      <Elem
        {...attrs}
      >
        {isReturnLink && <ChevronLeft className='faIcon backIcon' />}
        {text && text}
        {!isReturnLink && <ChevronRight className='faIcon linkIcon' />}
      </Elem>
    </Wrapper>
  );
};

export default PrimaryLink;

PrimaryLink.propTypes = {
  href: PropTypes.string,
  isReturnLink: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  text: PropTypes.string,
  target: PropTypes.string,
  id: PropTypes.string
};

PrimaryLink.defaultProps = {
  target: '_blank'
};
