import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { StyledButton } from 'components/core';

const Wrapper = styled.header`
  background: ${props => props.theme.colors.primary.hero};
  height: 40vh;
  position: relative;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    height: 300px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: 275px;
  }
`;

const Text = styled.div`
  color: ${props => props.theme.colors.white.base};
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
  h1 {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
`;

const Subtitle = styled.h2`
  max-width: 650px;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
`;

const Header = ({ children, title, cover, hasButton, buttonUrl, buttonText }) => (
  <Wrapper>
    {cover && <Img fluid={cover || {} || [] || ''} />}
    <Text>
      <h1>{title}</h1>
      {children && <Subtitle>{children}</Subtitle>}
      {hasButton &&
        <a href={buttonUrl}><StyledButton color='secondary' size='medium' variant='contained' type='text' text={buttonText} noShadow /></a>
      }
    </Text>
  </Wrapper>
);

export default Header;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  hasButton: PropTypes.bool,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string
};

Header.defaultProps = {
  children: false,
  cover: false,
  title: false,
  hasButton: false
};
