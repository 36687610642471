import React from 'react';
import { CheckCircle, ExclamationCircle, ExclamationTriangle } from '@styled-icons/fa-solid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const icons = {
  failure: <ExclamationCircle className='icon failure' />,
  warning: <ExclamationTriangle className='icon warning' />,
  success: <CheckCircle className='icon success' />
};

const StyledAccordion = ({ content, data, heading, headingIcon, secondaryHeading, secondaryIcon, thirdHeading, thirdIcon, id, onChange }) => {
  const Wrapper = styled.div`
    margin: 1rem 0 2rem 0;
    ul {
      list-style: none;
      margin-left: 0;
    }
    ul li.pass:before {
      content: '✓ ';
    }
    .MuiAccordion-root {
      width: 100%;
    }
    .MuiAccordionSummary-root {
      background: ${props => props.theme.colors.white.light};
    }
    .MuiAccordionDetails-root {
      background: ${props => props.theme.colors.white.white};
    }
    .MuiTypography-root {
      font-family: ${props => props.theme.fontFamily.body};
    }
    .heading {
      flex-basis: ${secondaryHeading ? '33.33%' : '66.66%'};
      flex-shrink: 0
    }
    .icon {
      width: 1rem;
      height: 1rem;
      margin: .2rem .5rem 0 0;
      vertical-align: middle;
    }
    .failure {
      color: ${props => props.theme.colors.red.base};
    }
    .success {
      color: ${props => props.theme.colors.teal.base};
    }
    .warning {
      color: ${props => props.theme.colors.orange.base};
    }
  `;
  const Header = () => {
    if (secondaryHeading && !thirdHeading) {
      return (
        <>
          {icons[headingIcon]}<Typography className='heading'>{heading}</Typography>
          {icons[secondaryIcon]}<Typography className='heading'>{secondaryHeading}</Typography>
        </>
      );
    } else if (secondaryHeading && thirdHeading) {
      return (
        <>
          {icons[headingIcon]}<Typography className='heading'>{heading}</Typography>
          {icons[secondaryIcon]}<Typography className='heading'>{secondaryHeading}</Typography>
          {icons[thirdIcon]}<Typography>{thirdHeading}</Typography>
        </>
      );
    } else {
      return (
        <>
          {icons[headingIcon]}<Typography className='heading'>{heading}</Typography>
        </>
      );
    }
  };

  return (
    <Wrapper>
      <Accordion onChange={onChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='accordion-content'
          id={id || 'accordion-header'}
          data-cy={data}
        >
          {Header()}
        </AccordionSummary>
        <AccordionDetails data-cy='accordion-body'>
          {content}
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

export default StyledAccordion;

StyledAccordion.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.string,
  heading: PropTypes.string,
  headingIcon: PropTypes.string,
  secondaryHeading: PropTypes.string,
  secondaryIcon: PropTypes.string,
  thirdHeading: PropTypes.string,
  thirdIcon: PropTypes.string,
  theme: PropTypes.object,
  id: PropTypes.string,
  onChange: PropTypes.func
};
