import React, { Fragment } from 'react';
import { css, Global, ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { Footer, NavBar } from 'layouts';
import theme from '../../config/theme';
import headroom from '../styles/headroom';

const BlogLayout = ({ children, location, article, pathname, seoTitle, seoDescription, seoImage }) => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <Global
        id='top'
        styles={css`
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          html {
            text-rendering: optimizeLegibility;
            box-sizing: border-box;
            -ms-overflow-style: scrollbar;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          html,
          body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }
          a {
            color: ${theme.colors.link.base};
            transition: color 0.5s;
            text-decoration: none;
          }
          a:hover {
            text-decoration: none;
            color: ${theme.colors.link.hover};
          }
          h1 {
            font-family: ${theme.fontFamily.heading};
          }

          ${headroom}
        `}
      />
      <SEO
        title={seoTitle}
        description={seoDescription}
        banner={seoImage}
        article={article}
        pathname={pathname}
      />
      <NavBar location={location} />
      {children}
      <Footer />
      {/* <CookieContainer /> */}
    </Fragment>
  </ThemeProvider>
);

export default BlogLayout;

BlogLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  article: PropTypes.string,
  pathname: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoImage: PropTypes.string,
  location: PropTypes.object
};
