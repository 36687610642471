import React from 'react';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBDropdownLink, MDBBtn } from 'mdb-react-ui-kit';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  .btn-primary {
    background-color: ${props => props.theme.colors.button.primary};
    color: ${props => props.theme.colors.button.primaryText};
    border-color: ${props => props.theme.colors.button.primary};
    font-family: ${props => props.theme.fontFamily.body};
  }
  .btn-primary:hover {
    background-color: ${props => props.theme.colors.button.primaryHover};
    color: ${props => props.theme.colors.button.primaryText};
  }
  .btn-primary:active {
    background-color: ${props => props.theme.colors.button.primaryActive} !important;
    border-color: ${props => props.theme.colors.button.primaryActive} !important;
  }
  .btn-secondary {
    background-color: ${props => props.theme.colors.button.secondary};
    color: ${props => props.theme.colors.button.secondaryText};
    border-color: ${props => props.theme.colors.button.secondaryActive};
    font-family: ${props => props.theme.fontFamily.body};
  }
  .btn-secondary:hover {
    background-color: ${props => props.theme.colors.button.secondaryHover};
    color: ${props => props.theme.colors.button.secondaryText};
  }
  .btn-secondary:active {
    background-color: ${props => props.theme.colors.button.secondaryActive} !important;
    border-color: ${props => props.theme.colors.button.secondaryActive} !important;
  }
`;

const SplitButton = ({ color, data, loadRepo, offset, onClick, options, text, type }) => {
  const loadPreview = (url) => {
    loadRepo(url);
  };
  return (
    <Wrapper>
      <MDBDropdown group options={{
        modifiers:
        [
          {
            name: 'offset',
            options: {
              offset: offset
            }
          }
        ]
      }}>
        <MDBBtn color={color} onClick={onClick} type={type} data-cy={data}>
          {text}
        </MDBBtn>
        <MDBDropdownToggle split></MDBDropdownToggle>
        <MDBDropdownMenu color={color}>
          {options.map(option => (
            <MDBDropdownItem key={option.url}>
              <MDBDropdownLink onClick={loadRepo ? () => loadPreview(option.url) : null} href={loadRepo ? '#' : option.url}>{option.title}</MDBDropdownLink>
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </Wrapper>
  );
};

export default SplitButton;

SplitButton.propTypes = {
  color: PropTypes.string,
  data: PropTypes.string,
  loadRepo: PropTypes.func,
  noShadow: PropTypes.bool,
  offset: PropTypes.array,
  onClick: PropTypes.func,
  options: PropTypes.array.isRequired,
  text: PropTypes.string,
  type: PropTypes.string
};

SplitButton.defaultProps = {
  color: 'primary',
  noShadow: true,
  offset: [0, 0],
  options: [],
  type: 'text'
};
