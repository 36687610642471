import React from 'react';
import styled from '@emotion/styled';
import image from '../../static/img/home/testimonial.svg';
import testimonialLogo from '../../static/img/home/testimonial-logo.svg';
import logo1 from '../../static/img/home/logo1.svg';
import logo2 from '../../static/img/home/logo2.svg';
import logo3 from '../../static/img/home/logo3.svg';
import logo4 from '../../static/img/home/logo4.svg';
import logo5 from '../../static/img/home/logo5.svg';
import logo6 from '../../static/img/home/logo6.svg';

const Wrapper = styled.div`
  padding: 2rem 20rem;
  background: ${props => props.theme.colors.white.light};
  height: 100%;
  font-style: normal;
  text-align: center;
  h2 {
    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  p.quote {
    margin-top: 2rem;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }
  .inlineIcon {
    display: inline;
    width: 20px;
    margin-right: 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 2rem 1rem;
  }
`;

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const LogosContainer = styled.div`
  margin: 50px auto 0 auto;
  max-width: 800px;
  .img {
    width: 80px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 80vw;
    .img {
      width: 60px;
    }
  }
`;

const Logos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 20px;
  }
`;

const Testimonial = () => (
  <Wrapper>
    <Container>
      <img src={image} alt='globe' />
      <h2>Trusted around the world.</h2>
      <p>From insurance to healthcare to creative, teams all over the world are using Stackery to go serverless.</p>
      <p className='quote'>“Stackery is a fantastic tool that helps you get started with serverless and leverage best practices and multiple services from AWS on day one.”</p>
      <p><img className='inlineIcon' src={testimonialLogo} alt='IKON logo' />Rescio Souza — Cloud Solutions Architect, IKON</p>
      <LogosContainer>
        <Logos>
          <img src={logo1} alt='logo' />
          <img src={logo2} alt='logo' />
          <img src={logo3} alt='logo' />
          <img src={logo4} alt='logo' />
          <img src={logo5} alt='logo' />
          <img src={logo6} alt='logo' />
        </Logos>
      </LogosContainer>
    </Container>
  </Wrapper>
);

export default Testimonial;
