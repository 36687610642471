import React from 'react';
import { Remarkable } from 'remarkable';
import { defineCustomElements as deckDeckGoElement } from '@deckdeckgo/highlight-code/dist/loader';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

// Initialize code highlighting
deckDeckGoElement();

/**
* Blog post content
*
* This file renders a the content of each blog post.
* (does not include header - see ArticleHeader.jsx)
*/

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto 1rem auto;
  img, deckgo-highlight-code {
    box-shadow: ${props => props.theme.shadow.image};
    border-radius: 4px;
    width: 80%;
    margin: 2rem auto;
    display: block;
    height: auto;
  }
  deckgo-highlight-code {
    padding: 10px;
    font-size: 1.05rem;
    border-left: 6px solid ${props => props.theme.colors.blue.base};
    font-feature-settings: normal;
    --deckgo-highlight-code-carbon-background: #FAFAFA;
    --deckgo-highlight-code-carbon-color: rgb(56, 58, 66);
    --deckgo-highlight-code-token-atrule: #A626A4;
    --deckgo-highlight-code-token-comment: #65737E;
    --deckgo-highlight-code-token-function: #4078F2;
    --deckgo-highlight-code-token-operator: #0184BC;
    --deckgo-highlight-code-token-property: #4078F2;
    --deckgo-highlight-code-token-punctuation: rgb(56, 58, 66);
    --deckgo-highlight-code-token-regex: rgb(80, 161, 79);
    --deckgo-highlight-code-token-selector: #E45649;
  }
  iframe {
    width: 100%;
    margin: 10px auto;
    min-height: 50vh;
  }
  .twitter-tweet > iframe {
    min-height: max-content;
    margin-bottom: 2rem;
  }
  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    font-weight: 300;
    line-height: 2;
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }
  blockquote {
    color: ${props => props.theme.colors.blue.hover};
    font-size: 1.25rem;
    text-align: center;
    margin: 5%;
  }
  a:not(.gatsby-resp-image-link):not(.anchor) {
    color: black;
    box-shadow: inset 0 -2px 0 ${props => props.theme.colors.primary.base};
    border-bottom: 1px solid ${props => props.theme.colors.primary.base};
    transition: ${props => props.theme.transitions.default.transition};
    text-decoration: none;
    &:hover,
    &:focus {
      background: ${props => props.theme.colors.primary.base};
      color: black;
    }
  }
  ul {
    list-style-type: square;
  }
  ol, ul {
    margin: 1rem 0 2rem 3rem;
  }
  ul, ol li {
    margin-bottom: 0;
  }
  p code, li code {
    padding: 0;
    margin: 0;
    font-family: "Ubuntu Mono", Inconsolata, courier, monospace;
    font-size: inherit;
    word-break: normal;
    color: ${props => props.theme.colors.red.base};
  }
  .gcms-codeblock {
    display: block;
    background-color: ${props => props.theme.colors.black.terminal};
    text-align: left;
    padding: 20px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    overflow: auto;
    border-top: 50px solid ${props => props.theme.colors.white.white};
    position: relative;
    border-radius: 4px;
    margin: 20px 0 40px 0;
    -webkit-box-shadow: 5.665px 9.429px 35px 0 rgba(0,0,0,0.2);
    box-shadow: 5.665px 9.429px 35px 0 rgba(0,0,0,0.2);
  }
  .gcms-codeblock p {
    font-family: "Ubuntu Mono",Inconsolata,courier,monospace;
    font-size: 1.15rem !important;
    line-height: .7 !important;
  }
  h1 {
    margin: 2rem 0 1rem 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
    position: relative;
    a {
      box-shadow: none;
      border-bottom: none;
      &:hover,
      &:focus {
        background: none;
      }
    }
    &:hover {
      .anchor svg {
        opacity: 0.8;
      }
    }
  }
  h2,
  h3 {
    display: block;
    margin: 2rem 0 1rem 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 85vw;
    iframe {
      min-height: 40vh;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    img, deckgo-highlight-code {
      width: 100%;
    }
    iframe {
      min-height: 50vh;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 85vw;
    iframe {
      min-height: 40vh;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 85vw;
    iframe {
      min-height: 30vh;
    }
    p,
    ul,
    ol,
    li {
      font-size: 1rem;
      line-height: 1.9rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    iframe {
      min-height: 20vh;
    }
  }
`;

// This is for syntax highlighting of markdown code blocks
// coming from GraphCMS
const codeRule = (inline) =>
  (tokens, idx, _options, _env) => {
    return `<deckgo-highlight-code 
            ${inline ? 'class="inline"' : ''}
            terminal="none"
            language="${tokens[idx].params
                ? tokens[idx].params
                : 'javascript'}">
                    <code slot="code">${tokens[idx].content}</code>
        </deckgo-highlight-code>`;
  };

const Content = ({ markdownContent, richTextContent }) => {
  const md = new Remarkable({
    html: true,
    xhtmlOut: true,
    breaks: true
  });

  md.renderer.rules.fence = codeRule(false);

  const input = markdownContent ? md.render(markdownContent) : richTextContent;

  return (
    <Wrapper dangerouslySetInnerHTML={{ __html: input }} />
  );
};

export default Content;

Content.propTypes = {
  markdownContent: PropTypes.string,
  richTextContent: PropTypes.string
};
