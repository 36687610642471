import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Feature } from 'components';

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white.white};
  height: 100%;
  padding: 0 2rem;
  div {
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0;
  }
`;

const FeatureColumn = ({ data }) => {
  const { feature } = data;

  return (
    <Wrapper>
      {feature && feature.map((f, i) => {
        return (
          <Feature
            key={f.id}
            icon={f.featureIcon && f.featureIcon.url}
            title={f.featureTitle}
            text={f.featureText}
            linkText={f.linkText}
            linkUrl={f.linkURL}
          />
        );
      })}
    </Wrapper>
  );
};

export default FeatureColumn;

FeatureColumn.propTypes = {
  data: PropTypes.object.isRequired
};
