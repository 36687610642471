import styled, { css } from 'styled-components';
import SearchResult from './SearchResult';

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: -20em;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 40em;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  padding: 1em;
  border-radius: 2px;
  background: ${({ theme }) => theme.background};
  span {
    display: inline;
  }
  .ais-Highlight-highlighted, .ais-Snippet-highlighted {
    background-color: ${({ theme }) => theme.highlight};
  }
  @media (max-width: 1199px) {
    max-width: 30em;
  }
`;

export default styled(SearchResult)`
  display: ${props => (props.show ? 'block' : 'none')};
  ${Popover}
  .HitCount {
    display: flex;
    justify-content: flex-end;
  }
  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }
    li.ais-Hits-item {
      margin-bottom: 1em;
      a {
        color: ${({ theme }) => theme.text};
        h4 {
          font-size: 1.2rem;
          margin-bottom: 0.2em;
        }
      }
    }
  }
`;
