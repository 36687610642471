import styled, { css } from 'styled-components';
import SearchBox from './SearchBox';

const open = css`
  background: ${({ theme }) => theme.background};
  width: 20em;
  cursor: text;
  margin-left: -1.6em;
  padding-left: 1.6em;
  @media (max-width: 1199px) {
    width: 10em;
  }
`;

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`;

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  .SearchInput {
    outline: none;
    border: none;
    font-size: 1em;
    transition: 200ms;
    border-radius: 2px;
    color: ${({ theme }) => theme.foreground};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }
  .SearchInput:focus {
    border-color: ${({ theme }) => theme.icon} !important;
  }
  .SearchIcon {
    width: 1em;
    margin: 0;
    color: ${({ theme }) => theme.icon};
    pointer-events: none;
    width: 20px;
    padding-top: 2px;
  }
  .SearchIcon:hover {
    color: ${({ theme }) => theme.iconHover};
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
