/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Github } from '@styled-icons/fa-brands';
import { RedoAlt, ExclamationCircle } from '@styled-icons/fa-solid';
import PropTypes from 'prop-types';
import { CanvasIframe, SecondaryLink, StyledButton } from 'components/core';

const Wrapper = styled.div`
  max-width: ${props => props.theme.layout.base};
  padding: 2rem;
  margin: 0 auto 2rem auto;
  text-align: center;
  button {
    margin: 1rem 0;
  }
  .warning {
    color: ${props => props.theme.colors.red.base};
  }
  .icon {
    width: 2rem;
    margin-bottom: 0.5rem;
    vertical-align: middle;
  }
  .gitIcon {
    color: ${props => props.theme.colors.white.white};
    width: 1rem;
    height: 1rem;
    margin: 0 .5rem .2rem 0;
    vertical-align: middle;
  }
  .retryIcon {
    color: ${props => props.theme.colors.link.base};
    width: 1rem;
    margin: 0 0.5rem 0.2rem 0;
    vertical-align: middle;
  }
`;

const CanvasDiv = styled.div`
  iframe {
    width: 100%;
  }
`;

const ErrorHandler = ({ error, hasYamlError, isPrivateRepo, repoUrl }) => {
  const parsedUrl = repoUrl.split('/');
  const canvasUrl = `https://app.stackery.io/editor/design?owner=${parsedUrl[3]}&repo=${parsedUrl[4]}&ref=${parsedUrl[6]}&file=${parsedUrl.slice(7).join('/')}`;
  function refreshPage () {
    window.location.replace(window.location.pathname);
  }
  const githubButtonText = () => {
    return <span><Github className='gitIcon' />Authenticate GitHub</span>;
  };
  return (
    <Wrapper>
      <h2 className='warning'><ExclamationCircle className='icon' /> An error occured: {error.name ? error.name : error}</h2>
      <p>Template: {repoUrl}</p>
      {hasYamlError
        ? <CanvasDiv>
            <p>We&apos;re unable to show audit results on this template due to an issue with the template file. You can see the specific errors and line numbers for your template in our editor below:</p>
              <CanvasIframe
                id='customPageCanvas'
                height='600'
                url={canvasUrl}
              />
          </CanvasDiv>
        : <p>{error.message ? error.message : error}</p>
      }
      {isPrivateRepo &&
        <Link to='/sign-in' id='authorize-github-from-404'><StyledButton color='primary' size='large' variant='contained' type='text' text={githubButtonText()} noShadow /></Link>
      }
      <p><RedoAlt className='retryIcon' /><SecondaryLink onClick={refreshPage} text='Try another project' /></p>
      <p>If you have any questions, please contact Stackery Support using the chat icon on the lower right, and we&apos;ll get back to you as soon as possible.</p>
    </Wrapper>
  );
};

export default ErrorHandler;

ErrorHandler.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  hasYamlError: PropTypes.bool,
  isPrivateRepo: PropTypes.bool,
  repoUrl: PropTypes.string.isRequired
};
