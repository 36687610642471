export const colors = {
  white: {
    white: '#FFFFFF',
    base: '#5F5F6E',
    light: '#F0F3F4',
    dark: '#E5E5E5',
    gray: '#888888',
    terminal: '#FFF6CA'
  },
  black: {
    base: '#0A2834',
    light: '#4b4e57',
    blue: '#0A2834',
    terminal: '#002935'
  },
  gray: {
    base: '#E5E5E5',
    lightest: '#fafafb',
    light: '#F0F3F4',
    dark: '#888888',
    border: '#ECEDEE'
  },
  primary: {
    base: '#79CCB5',
    hero: '#80CBB4',
    light: '#3e5fbc',
    dark: '#81B09F'
  },
  teal: {
    base: '#80CBB4',
    lighter: 'rgba(191, 226, 215, 0.2)',
    light: '#CCE8DF',
    link: '#34A380',
    active: '#2D8F72',
    dark: '#298066'
  },
  blue: {
    base: '#5B7D88',
    light: '#F0F3F4',
    hover: '#426C7B',
    active: '#294A57',
    dark: '#1A333D',
    darker: '#0A2834',
    black: '#0E1D24'
  },
  red: {
    base: '#C3482C',
    lighter: '#FCF6F4',
    light: '#E5AEA2',
    active: '#AB391F'
  },
  yellow: {
    base: '#FCD100',
    light: '#E7E0A6',
    dark: '#C1AE13'
  },
  orange: {
    base: '#D86613'
  },
  magenta: {
    base: '#BB0087',
    lighter: 'rgba(234, 175, 218, 0.2)',
    light: '#E18FCA'
  },
  background: {
    light: '#FFFFFF',
    dark: '#112834'
  },
  button: {
    primary: '#34A380',
    primaryActive: '#298066',
    primaryHover: '#2D8F72',
    primaryText: 'FFFFFF',
    secondary: '#FCD100',
    secondaryActive: '#C1AE13',
    secondaryHover: '#DEBA09',
    secondaryText: '#062932'
  },
  link: {
    base: '#34A380',
    hover: '#2D8F72',
    active: '#298066',
    disabled: '#D6E1E5'
  }
};

export const shadow = {
  card: '0 20px 30px rgba(0, 0, 0, 0.1)',
  image: '3px 3px 8px rgba(255, 255, 255, 0.3), 6px 6px 35px rgba(41, 74, 87, 0.1)',
  suggestion: '0 -5px 30px rgba(0,0,0,0.2)',
  footer: '0 -3px 26px rgba(0,0,0,0.5)',
  feature: {
    big: {
      default: '0 40px 40px rgba(0, 0, 0, 0.2)',
      hover: '0 50px 50px rgba(0, 0, 0, 0.1)'
    },
    small: {
      default: '0 15px 25px rgba(0, 0, 0, 0.2)',
      hover: '0 40px 45px rgba(0, 0, 0, 0.1)'
    }
  },
  text: {
    small: '0 5px 10px rgba(0, 0, 0, 0.25)',
    big: '0 15px 20px rgba(0, 0, 0, 0.13)'
  }
};

export const gradient = {
  // eslint-disable-next-line
  leftToRight: `linear-gradient(-45deg, ${colors.background.light} 0%, ${colors.background.dark} 100%)`,
  // eslint-disable-next-line
  rightToLeft: `linear-gradient(45deg, ${colors.background.light} 0%, ${colors.background.dark} 100%)`
};

export const transition = {
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  duration: '0.4s'
};

export const theme = {
  colors,
  gradient,
  shadow,
  breakpoints: {
    xs: '375px',
    s: '575px',
    m: '767px',
    l: '991px',
    xl: '1199px',
    xxl: '1399px',
    ginormous: '1599px'
  },
  maxWidth: '1400px',
  maxHeightBreakpoint: '939px',
  fontFamily: {
    // eslint-disable-next-line
    body: `Ubuntu, Open Sans, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // eslint-disable-next-line
    heading: `Ubuntu, Open Sans, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    // eslint-disable-next-line
    code: `Ubuntu Mono, Inconsolata, courier, monospace`
  },
  layout: {
    article: '46rem',
    base: '70rem',
    big: '83.33333rem'
  },
  borderRadius: {
    default: '0.4rem',
    round: '100rem'
  },
  transitions: {
    default: {
      duration: transition.duration,
      timing: transition.easeInOutCubic,
      transition: `all ${transition.duration} ${transition.easeInOutCubic}`
    },
    boom: {
      duration: transition.duration,
      timing: transition.easeOutBack,
      transition: `all ${transition.duration} ${transition.easeOutBack}`
    },
    headroom: {
      transition: 'all 0.25s ease-in-out'
    }
  }
};

export default theme;
