import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { StyledButton } from 'components/core';

const Wrapper = styled.header`
  background: ${props => props.theme.colors.primary.hero};
  min-height: 80vh;
  position: relative;
  overflow: hidden;
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    min-height: 100vh;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.black.base};
  z-index: 0;
  position: relative;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.theme.layout.base};
  padding: 0 2rem;
  margin-bottom: 3rem;
  align-items: center;
  button {
    margin-top: 1rem;
  }
  h1 {
    color: ${props => props.theme.colors.black.base};
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    top: 90px;
    h1 {
      font-size: 44px;
      line-height: 50px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 70px;
    h1 {
      font-size: 38px;
      line-height: 42px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    top: 150px;
    h1 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

const Subtitle = styled.p`
  color: ${props => props.theme.colors.black.base};
  max-width: 60vw;
  margin-top: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Hero = ({ children, cover, title, hasButton, buttonUrl, buttonText }) => (
  <Wrapper>
    <img src={cover} alt={title} />
    <Title>
      <h1>{title}</h1>
      {children && <Subtitle>{children}</Subtitle>}
      {hasButton &&
        <a href={buttonUrl}><StyledButton color='secondary' size='large' variant='contained' type='text' text={buttonText} noShadow /></a>
      }
    </Title>
  </Wrapper>
);

export default Hero;

Hero.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasButton: PropTypes.bool,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string
};

Hero.defaultProps = {
  children: false,
  cover: false,
  title: false,
  hasButton: false,
  buttonUrl: '',
  buttonText: ''
};
