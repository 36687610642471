import React from 'react';
import PropTypes from 'prop-types';

const CanvasIframe = ({ height, id, standalone, sync, syncName, url }) => {
  const canvasUrl = new URL(url);

  if (standalone) {
    canvasUrl.searchParams.append('standalone', true);
  }

  if (sync) {
    // If two iframes are on the same page, they can be synced between the editor and the template
    // by including the &sync param. The design editor gets &sync=stackeryEditorTemplate
    // and the template gets &sync=stackeryEditorDesign
    canvasUrl.searchParams.append('sync', syncName);
  }
  return (
    <iframe
      id={id || 'canvasIframe'}
      name={id}
      title='Stackery canvas'
      width='100%'
      height={height || '500'}
      frameBorder='0'
      src={canvasUrl.toString()}
    />
  );
};

export default CanvasIframe;

CanvasIframe.propTypes = {
  height: PropTypes.string,
  id: PropTypes.string,
  standalone: PropTypes.bool,
  sync: PropTypes.bool,
  syncName: PropTypes.string,
  url: PropTypes.string.isRequired
};
