import React, { Component } from 'react';
import { Carousel } from 'components';
import design from '../../static/img/home/carousel-design.svg';
import develop from '../../static/img/home/carousel-develop.svg';
import deliver from '../../static/img/home/carousel-deliver.svg';

class CarouselContainer extends Component {
  constructor () {
    super();
    this.state = {
      imageSrc: design,
      isDesignActive: true,
      isDevelopActive: false,
      isDeliverActive: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (event) {
    if (event.currentTarget.id === 'develop') {
      this.setState({
        imageSrc: develop,
        isDesignActive: false,
        isDevelopActive: true,
        isDeliverActive: false
      });
    } else if (event.currentTarget.id === 'deliver') {
      this.setState({
        imageSrc: deliver,
        isDesignActive: false,
        isDevelopActive: false,
        isDeliverActive: true
      });
    } else {
      this.setState({
        imageSrc: design,
        isDesignActive: true,
        isDevelopActive: false,
        isDeliverActive: false
      });
    }
  }

  render () {
    return (
      <Carousel
        handleClick={this.handleClick}
        imageSrc={this.state.imageSrc}
        isDesignActive={this.state.isDesignActive}
        isDevelopActive={this.state.isDevelopActive}
        isDeliverActive={this.state.isDeliverActive}
      />
    );
  }
}

export default CarouselContainer;
