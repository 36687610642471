import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import userIcon from '../../../static/img/icons/user.svg';

const Wrapper = styled.div`
  .btn-primary {
    color: inherit;
    background-color: inherit;
    border: none;
  }
  .btn-primary:hover {
  }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
  }
  .dropdown-menu {
    width: fit-content;
    margin: .125rem 0 0;
    background-color: ${props => props.theme.colors.white.white};
    background-clip: padding-box;
    border: none;
    border-radius: 4px;
    box-shadow: ${props => props.theme.shadow.image};
  }
  .dropdown-menu span {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .icon {
    width: 1.6rem;
    height: 1.6rem;
    vertical-align: middle;
  }
`;
const SignoutButton = ({ handleSignOut, username }) => {
  return (
    <Wrapper>
      <Dropdown>
        <Dropdown.Toggle id='signout-button'>
          <img className='icon' src={userIcon} alt='User Icon' />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.ItemText>Signed in as {username}</Dropdown.ItemText>
          <Dropdown.Divider />
          <Dropdown.Item href={process.env.GATSBY_STACKERY_APP} target='_blank'>Go to app</Dropdown.Item>
          <Dropdown.Item onClick={() => handleSignOut()}>Sign out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
};

export default SignoutButton;

SignoutButton.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired
};
