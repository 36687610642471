import algoliasearch from 'algoliasearch/lite';
import React, { createRef, useEffect, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import StyledSearchBox from './StyledSearchBox';
import StyledSearchResult from './StyledSearchResult';
import StyledSearchRoot from './StyledSearchRoot';
import useClickOutside from './UseClickOutside';

const theme = {
  foreground: '#5F5F6E',
  background: '#FFFFFF',
  faded: '#888888',
  icon: '#34A380',
  iconHover: '#2D8F72',
  text: '#0A2834',
  highlight: '#CCE8DF'
};

export default function Search ({ indices }) {
  const rootRef = createRef();
  const [query, setQuery] = useState();
  const [hasFocus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  useClickOutside(rootRef, () => {
    setFocus(false);
    setQuery('');
  });

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, [1000]);

  return (
    visible
      ? <ThemeProvider theme={theme}>
      <StyledSearchRoot ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
          <StyledSearchResult
            show={query && query.length > 0 && hasFocus}
            indices={indices}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
      : null
  );
}

Search.propTypes = {
  indices: PropTypes.array.isRequired
};
