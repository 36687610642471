import React from 'react';
import { Button } from '@material-ui/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  .MuiButton-containedPrimary {
    background-color: ${props => props.theme.colors.button.primary};
    color: ${props => props.theme.colors.button.primaryText};
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiButton-containedPrimary:hover {
    background-color: ${props => props.theme.colors.button.primaryHover};
    color: ${props => props.theme.colors.button.primaryText};
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiButton-containedSecondary {
    background-color: ${props => props.theme.colors.button.secondary};
    color: ${props => props.theme.colors.button.secondaryText};
    font-family: ${props => props.theme.fontFamily.body};
  }
  .MuiButton-containedSecondary:hover {
    background-color: ${props => props.theme.colors.button.secondaryHover};
    color: ${props => props.theme.colors.button.secondaryText};
    font-family: ${props => props.theme.fontFamily.body};
  }
`;

const StyledButton = ({ color, data, disabled, noShadow, onClick, size, text, type, variant }) => {
  return (
    <Wrapper>
      <Button onClick={onClick} color={color} size={size} variant={variant} type={type} data-cy={data} disableElevation={noShadow} disabled={disabled} >
        {text}
      </Button>
    </Wrapper>
  );
};

export default StyledButton;

StyledButton.propTypes = {
  color: PropTypes.string,
  data: PropTypes.string,
  disabled: PropTypes.bool,
  noShadow: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
};

StyledButton.defaultProps = {
  color: 'primary',
  disabled: false,
  noShadow: true,
  size: 'medium',
  type: 'text',
  variant: 'contained'
};
