/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Search from '../components/search';
import logo from '../../static/logo/logo.svg';

const searchIndices = [{ name: 'stackery_site', title: 'stackery_site' }];

const Header = styled.header`
  background: ${props => props.theme.colors.white.white};
  display: block;
`;

const Nav = styled.nav`
  position: relative;
  list-style: none;
  ul.navbar-nav {
    flex-direction: row !important;
  }
  ul a {
    color: ${props => props.theme.colors.link.base};
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 20px;
    margin-top: 12px;
  }
  .navbar-nav .nav-link {
    padding: 8px 12px;
  }
  ul a.active {
    color: ${props => props.theme.colors.link.active};
  }
  ul a:hover {
    color: ${props => props.theme.colors.link.hover};
    text-decoration: none;
  }
  li.nav-button {
    margin-left: 1rem;
  }
  img.logo-top {
    width: 230px;
    height: 42px;
    transition: 0.3s;
  }
  .nav-item {
    cursor: pointer;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    ul.navbar-nav {
      margin-top: 10px;
    }
    img.logo-top {
      max-width: 50vw;
    }
    li.nav-button a {
      font-size: .8rem;
      margin: auto auto 5px 10px;
    }
    a.nav-link {
      font-size: 1rem;
    }
    ul a {
      margin-top: 0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    ul.navbar-nav {
      flex-direction: row !important;
      margin: 10px auto auto auto !important;
      justify-content: center;
    }
    li.nav-button a {
      display: none;
    }
    a.nav-link {
      font-size: .8rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    .navbar-nav .nav-link {
      padding: 8px 10px;
    }
    a.nav-link {
      font-size: .75rem;
    }
  }
`;

function getClassName (location, href) {
  if (location.includes(href)) {
    return 'active nav-link link-scroll';
  } else {
    return 'nav-link link-scroll';
  }
}

const NavBar = ({ location }) => {
  // Not every page has a location - this prevents errors by providing a default
  const locationPath = location ? location.pathname : '/';

  return (
    <Header className='header'>
      <Nav className='navbar navbar-expand-lg fixed-top'>
        <a href='/' className='navbar-brand'>
          <img src={logo} className='logo-top' alt='Stackery Logo' />
        </a>
        <ul className='navbar-nav ml-auto align-items-end'>
          <li className='nav-item'>
            <Search indices={searchIndices} />
          </li>
          <li className='nav-item'>
            <a href='/blog' className={getClassName(locationPath, '/blog')}>Blog</a>
          </li>
        </ul>
      </Nav>
    </Header>
  );
};

export default NavBar;

NavBar.propTypes = {
  location: PropTypes.object
};
