import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Feature } from 'components';
import { PrimaryLink } from 'components/core';

const Wrapper = styled.div`
  padding: 4rem;
  background: ${props => props.theme.colors.white.white};
  height: 100%;
`;

const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`;

const Callout = styled.div`
  margin-bottom: 4rem;
  padding: 0 16rem;
  font-style: normal;
  text-align: center;
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  p.quote {
    margin-top: 2rem;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 4rem 0 2rem 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0;
  }
`;

const FeatureSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  font-style: normal;
  text-align: left;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`;

const FeatureGroup = ({ features }) => {
  const {
    mainFeature,
    feature
  } = features;

  return (
    <Wrapper>
      <Container>
        {mainFeature &&
          <Callout>
            {mainFeature.featureIcon && <img src={mainFeature.featureIcon.url} alt='icon' />}
            <h2>{mainFeature.featureTitle}</h2>
            <p>{mainFeature.featureText}</p>
            {mainFeature.linkURL && <PrimaryLink to={mainFeature.linkURL} text={mainFeature.linkText} />}
          </Callout>
        }
        <FeatureSection>
          {feature && feature.map((f, i) => {
            return (
              <Feature
                key={f.id}
                icon={f.featureIcon && f.featureIcon.url}
                title={f.featureTitle}
                text={f.featureText}
                linkText={f.linkText}
                linkUrl={f.linkURL}
              />
            );
          })}
        </FeatureSection>
      </Container>
    </Wrapper>
  );
};

export default FeatureGroup;

FeatureGroup.propTypes = {
  features: PropTypes.object.isRequired
};
