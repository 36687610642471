import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.span`
  a, span {
    color: ${props => props.theme.colors.link.base};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  a:hover, span:hover {
    color: ${props => props.theme.colors.link.hover};
    text-decoration: underline;
  }
  a:active, span:active {
    text-decoration: underline;
    color: ${props => props.theme.colors.link.active};
  }
`;

const SecondaryLink = ({ href, onClick, target, text, to, id }) => {
  let Elem = 'a';
  const attrs = { href: href, target: target, onClick: onClick, id };

  if (to) {
    Elem = to.includes('#') ? AnchorLink : Link;
    attrs.to = to;
  } else if (!href && !to) {
    Elem = 'span';
  }

  return (
    <Wrapper>
      <Elem
        {...attrs}
      >
        {text && text}
      </Elem>
    </Wrapper>
  );
};

export default SecondaryLink;

SecondaryLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  id: PropTypes.string
};

SecondaryLink.defaultProps = {
  target: '_blank'
};
