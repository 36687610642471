import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { StyledButton } from 'components/core';

const Wrapper = styled.header`
  background: ${props => props.theme.colors.primary.hero};
  min-height: 40vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  img {
    width: 100%;
  }
  .icon {
    width: 70px;
    margin-top: 40px;
    background: ${props => props.theme.colors.primary.hero};
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    height: 40vh;
    .icon {
      margin-top: 55px;
    }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    .icon {
      width: 60px;
      margin-top: 65px;
    }
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.black.base};
  max-width: ${props => props.theme.maxWidth};
  padding: 0 8rem 2rem 8rem;
  align-items: center;
  h1 {
    color: ${props => props.theme.colors.black.base};
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
  }
  button {
    margin-top: 5%;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    h1 {
      font-size: 44px;
      line-height: 50px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 2rem 2rem 2rem;
    h1 {
      font-size: 38px;
      line-height: 42px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    h1 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

const Subtitle = styled.p`
  color: ${props => props.theme.colors.black.base};
  max-width: 60vw;
  margin: auto;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 90vw;
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const IntegrationHeader = ({ cover, icon, title, subtitle }) => (
  <Wrapper>
    {cover &&
      <img src={cover} alt={title} />
    }
    {icon &&
      <img className='icon' src={icon} alt={title} />
    }
    <Title>
      <h1>{title}</h1>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <a href='/product'>
          <StyledButton color='secondary' size='medium' variant='contained' type='text' text='Learn More About Stackery' noShadow />
        </a>
    </Title>
  </Wrapper>
);

export default IntegrationHeader;

IntegrationHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  cover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

IntegrationHeader.defaultProps = {
  children: false,
  cover: false,
  title: false
};
